"use client";

import { getURL } from "@/utils/get-url";
import * as Sentry from "@sentry/nextjs";
import { createClient } from "@v1/supabase/client";
import { Button } from "@v1/ui/button";

export const GoogleSignin = Sentry.withErrorBoundary(
  function GoogleSignin() {
    const supabase = createClient();

    const handleSignin = () => {
      try {
        supabase.auth.signInWithOAuth({
          provider: "google",
          options: {
            redirectTo: `${getURL()}api/auth/callback`,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error during Google sign-in:", error);
      }
    };

    return (
      <Button
        onClick={handleSignin}
        variant="secondary"
        className="font-mono w-full"
        type="button"
      >
        Sign in with Google
      </Button>
    );
  },
  {
    fallback: (props) => (
      <div>An error occurred in the GoogleSignin component.</div>
    ),
  },
);
