"use client";

import * as Sentry from "@sentry/nextjs";
import { createClient } from "@v1/supabase/client";
import { Button } from "@v1/ui/button";
import { Input } from "@v1/ui/input";
import { useRouter } from "next/navigation";
import { type FormEvent, useState } from "react";

export const SimpleLogin = Sentry.withErrorBoundary(
  function SimpleLogin() {
    const router = useRouter();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const supabase = createClient();

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError(null);
      setIsLoading(true);

      try {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;
        console.log("Login successful");
        router.push("/dashboard");
      } catch (err) {
        Sentry.captureException(err);
        console.error("Unexpected error during login:", err);
        setError("Invalid email or password. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <form onSubmit={handleLogin} className="space-y-4">
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          type="submit"
          className="w-full"
          variant="default"
          disabled={isLoading}
        >
          {isLoading ? "Signing In..." : "Sign In"}
        </Button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    );
  },
  {
    fallback: (props) => (
      <div>An error occurred in the SimpleLogin component.</div>
    ),
  },
);
